<template>
  <div class="with-nav-bar">
    <van-nav-bar
      :title="state.titleText"
      left-text="返回"
      :left-arrow="true"
      :fixed="true"
      @click-left="$router.back()"
    />
    <van-loading v-if="state.loading" class="mt-3 text-center" vertical
      >加载中</van-loading
    >
    <div v-else>
      <van-field
        v-model="state.station_name"
        readonly
        clickable
        label="所属电站"
        placeholder="点击选择充电站"
        @click="state.showPicker = true"
      />
      <van-popup v-model:show="state.showPicker" position="bottom" round>
        <van-picker
          confirm-button-text="选择"
          :columns="state.columns"
          @confirm="chooseStation"
          @cancel="state.showPicker = false"
        />
      </van-popup>
      <van-field
        v-if="!state.id"
        v-model="state.pile_code"
        label="设备编码"
        placeholder="扫描外壳或屏幕上的二维码"
        maxlength="13"
        right-icon="scan"
        @click-right-icon="scanPileCode"
      />
      <van-field v-else v-model="state.pile_code" label="设备编码" disabled />
      <van-field
        v-model="state.name"
        label="设备名称"
        placeholder="例如: 一号机"
      />
      <van-field
        v-model="state.iccid"
        label="SIM ICCID"
        placeholder="集成电路卡识别码"
      />
      <van-field label="充电枪数量">
        <template #input>
          <van-radio-group v-model="state.gun_num" direction="horizontal">
            <van-radio :name="1" shape="square">单枪</van-radio>
            <van-radio :name="2" shape="square">双枪</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field label="充电枪类型">
        <template #input>
          <van-radio-group v-model="state.gun_type" direction="horizontal">
            <van-radio :name="2" shape="square">交流</van-radio>
            <van-radio :name="1" shape="square">直流</van-radio>
            <van-radio :name="3" shape="square">一体机</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field label="单枪功率">
        <template #input>
          <van-radio-group v-model="state.power" direction="horizontal">
            <van-radio name="p7" shape="square">7kW</van-radio>
            <van-radio name="p20" shape="square">20kW</van-radio>
            <van-radio name="p120" shape="square">120kW</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-cell
        center
        title="附近扫码"
        label="开启后用户只能在充电桩附近操作充电"
      >
        <template #right-icon>
          <van-switch v-model="state.scan_nearby" size="24" />
        </template>
      </van-cell>
      <div class="py-4 action-button-area text-center">
        <van-button v-if="!canSubmit" type="primary" disabled>{{
          state.buttonText
        }}</van-button>
        <van-button v-else type="primary" @click="submitForm">{{
          state.buttonText
        }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
import { onMounted, computed, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { Field, Toast, Popup, Picker, Switch, RadioGroup, Radio } from "vant";
import { bindDevice, loadDevice, updateDevice } from "@/api/device.service";
import { getSimpleStationList } from "@/api/station.service";
import { loadAddressFromSession } from "@/api/map.service";
import { signaturePack } from "@/api/wechat.service";
import { getValidateScanResult } from "@/utils/util";
export default {
  name: "StationForm",
  components: {
    [Field.name]: Field,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Switch.name]: Switch,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const state = reactive({
      id: 0, // 有ID为更新, ID=0为添加
      station_id: 0,
      station_name: "点击选择充电站",
      pile_code: "",
      name: "",
      iccid: "",
      gun_num: 1,
      gun_type: 1,
      power: "p7",
      scan_nearby: true,
      titleText: "加载中",
      buttonText: "加载中",
      showPicker: false,
      columns: [{ values: [], defaultIndex: 0 }],
      stationList: [],
      loading: false,
    });

    const canSubmit = computed(() => {
      if (!state.name || !state.pile_code || !state.station_id) {
        return false;
      }
      return true;
    });

    const loadStations = async () => {
      try {
        const { result } = await getSimpleStationList();
        state.stationList = result.items;
        state.stationList.map((item) => {
          state.columns[0].values.push(item.name);
        });
      } catch (error) {
        console.log(error);
      }
    };

    const chooseStation = (value) => {
      // 注意: 由于 columns 配置为数组形式, 这里取得的值也是数组
      // 虽然只有一列, 但是要设定默认选中的项目, 也需要配置为多列数组的形式
      state.showPicker = false;
      state.station_name = value[0];
      state.stationList.map((item) => {
        if (item.name == value[0]) {
          state.station_id = item.id;
          return;
        }
      });
    };

    const scanPileCode = async () => {
      await wx.scanQRCode({
        needResult: 1,
        scanType: ["qrCode"],
        success: function (res) {
          const code = getValidateScanResult(res);
          if (code && code.length == 14) {
            state.pile_code = code.substring(0, 13);
          } else {
            console.log(`res.errMsg=${res.errMsg}`);
            console.log(`res.resultStr=${res.resultStr}`);
            return Toast.fail({ message: "二维码无效", icon: "scan" });
          }
        },
      });
    };

    const loadForm = async (device_id) => {
      try {
        const { result } = await loadDevice(device_id);
        state.name = result.name;
        state.iccid = result.iccid;
        state.power = `p${result.power}`;
        state.gun_num = result.gun_num;
        state.gun_type = result.gun_type;
        state.pile_code = result.pile_code;
        state.station_id = result.station.id;
        state.station_name = result.station.name;
        state.scan_nearby = result.scan_nearby == 0 ? false : true;
        state.loading = false;
        // 默认选中 Picker 项目
        state.stationList.map((item, index) => {
          if (item.name == state.station_name) {
            state.columns[0].defaultIndex = index;
            return;
          }
        });
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const submitForm = async () => {
      let data = {
        id: state.id,
        station_id: state.station_id,
        name: state.name,
        iccid: state.iccid,
        gun_num: state.gun_num,
        gun_type: state.gun_type,
        power: state.power.replace("p", ""),
        scan_nearby:
          state.scan_nearby === true || state.scan_nearby == "true" ? 1 : 0,
      };
      if (!state.id) {
        data.pile_code = state.pile_code;
      }
      const caller = state.id == 0 ? bindDevice : updateDevice;
      const successTips = state.id == 0 ? "绑定成功" : "更新成功";
      try {
        await caller(data);
        Toast.success(successTips);
        router.back();
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({ message: msg ? msg : "前端脚本异常", position: "bottom" });
        if (!msg) console.log(error);
      }
    };

    const signaturePage = async () => {
      try {
        const { result } = await signaturePack(location.href.split("#")[0]);
        wx.config({
          appId: result.appId,
          timestamp: result.timestamp,
          nonceStr: result.nonceStr,
          signature: result.signature,
          jsApiList: ["hideOptionMenu", "scanQRCode"],
        });
        wx.ready(() => {
          wx.hideOptionMenu();
        });
        wx.error((res) => {
          Toast(res.errMsg);
        });
      } catch (error) {
        const { data, statusText } = error;
        const msg = data && data.msg ? data.msg : statusText;
        Toast({
          message: msg ? msg : "前端脚本异常/页面签名失败",
          position: "bottom",
        });
        if (!msg) console.log(error);
      }
    };

    onMounted(() => {
      // 页面签名
      signaturePage();

      // 必须先加载充电站列表, 因为 loadForm() 需要用到此数据
      loadStations();

      if (!route.query.id) {
        state.titleText = "绑定充电桩";
        state.buttonText = "确认绑定";
        // 自动填写部分表单
        const currentChoiceAddress = loadAddressFromSession();
        if (currentChoiceAddress) {
          state.area = currentChoiceAddress.area;
          state.address = currentChoiceAddress.address;
          state.longitude = currentChoiceAddress.lng;
          state.latitude = currentChoiceAddress.lat;
        }
      } else {
        state.id = route.query.id;
        state.titleText = "更新充电桩";
        state.buttonText = "确认更新";
        state.loading = true;
        loadForm(state.id);
      }
    });

    return { state, canSubmit, chooseStation, scanPileCode, submitForm };
  },
};
</script>
